// src/App.js
import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const CustomUrlPage = () => {
  const { custom_url } = useParams();
  const [isReady, setIsReady] = useState(false);
  const [error, setError] = useState(null);

  const fetchAndRedirect = useCallback(async () => {
    //const domain = 'sethub.club';
    const domain = document.domain;

    const base64_var = btoa(`${domain}/${custom_url}`);
    const s3_url = `https://pub-da695f9fdc864beb974a63150c62be0a.r2.dev/conf/${base64_var}.json`;

    try {
      const response = await fetch(s3_url);
      const data = await response.json();

      if (data.protocol) {
        const newUrl = `${data.protocol}://${data.subdomain}${data.domain}${data.path}?referrer=${data.parameters.referrer}&landing=${data.parameters.landing}&utm_source=${data.parameters.utm_source}&utm_medium=${data.parameters.utm_medium}&utm_campaign=${data.parameters.utm_campaign}&utm_content=${data.parameters.utm_content}&link_domain=${domain}&link_slug=${custom_url}`;
        window.location.href = newUrl;
      } else {
        setError('Invalid data received from the server');
      }
    } catch (error) {
      setError('Error fetching data');
      console.error('Error fetching data:', error);
    }
  }, [custom_url]);

  useEffect(() => {
    if (custom_url) {
      setIsReady(true);
      fetchAndRedirect();
    }
  }, [custom_url, fetchAndRedirect]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <div>{isReady ? 'Loading...' : 'Loading...'}</div>;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/:custom_url" element={<CustomUrlPage />} />
      </Routes>
    </Router>
  );
};

export default App;